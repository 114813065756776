import { defineMessages } from 'react-intl';

export const navigationMessages = defineMessages({
  about: {
    defaultMessage: 'About',
    description: 'about link in navigation',
    id: 'navigation.about',
  },
  allRightsReserved: {
    defaultMessage: 'All rights reserved',
    description: 'info in the footer',
    id: 'navigation.allRightsReserved',
  },
  articles: {
    defaultMessage: 'Articles',
    description: 'articles link in navigation',
    id: 'navigation.articles',
  },
  book: {
    defaultMessage: 'Book',
    description: 'book link in navigation',
    id: 'navigation.book',
  },
  contact: {
    defaultMessage: 'Contact',
    description: 'contact link in navigation',
    id: 'navigation.contact',
  },
  legal: {
    defaultMessage: 'Legal',
    description: 'legal link in navigation',
    id: 'navigation.legal',
  },
  media: {
    defaultMessage: 'Media',
    description: 'media link in navigation',
    id: 'navigation.media',
  },
  moments: {
    defaultMessage: 'Moments',
    description: 'moments link in navigation',
    id: 'navigation.moments',
  },
  privacyPolicy: {
    defaultMessage: 'Privacy Policy',
    description: 'privacy policy link in navigation',
    id: 'navigation.privacyPolicy',
  },
});
