import React, {
  forwardRef,
  Ref,
  useMemo,
  useState,
} from 'react';

import { MergeElementProps } from '@/core/interfaces/common';

import { theme } from '@/themes/main';

import {
  IconWrapper,
  StyledButton,
} from './Button.styles';
import {
  ButtonVariant,
  CustomButtonProps,
} from './Button.types';
import { getIconColor } from './Button.helpers';

export type ButtonProps = MergeElementProps<
  'button',
  CustomButtonProps
>

export const Button = forwardRef(({
  type = 'button',
  onClick,
  variant = ButtonVariant.PRIMARY,
  label,
  isDisabled,
  startIcon,
  endIcon,
  ...props
}: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const [
    isHover,
    setIsHover,
  ] = useState(false);

  const iconColor = useMemo(
    () => getIconColor({
      isDisabled,
      isHover,
      theme,
      variant,
    }),
    [
      variant,
      isHover,
      isDisabled,
    ]
  );

  return (
    <StyledButton
      ref={ref}
      type={type}
      onClick={onClick}
      variant={variant}
      disabled={isDisabled}
      startIcon={startIcon}
      endIcon={endIcon}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      { startIcon && (
        <IconWrapper isGhost={variant === ButtonVariant.GHOST}>
          { React.cloneElement(startIcon, { fill: iconColor }) }
        </IconWrapper>
      )}
      { label && <span>{label}</span> }
      { endIcon && (
        <IconWrapper isGhost={variant === ButtonVariant.GHOST}>
          { React.cloneElement(endIcon, { fill: iconColor }) }
        </IconWrapper>
      )}
    </StyledButton>
  );
});
