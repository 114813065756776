import React, {
  forwardRef,
  Ref,
  useMemo,
  useState,
} from 'react';

import { MergeElementProps } from '@/core/interfaces/common';

import { theme } from '@/themes/main';

import {
  StyledIconButton,
} from './IconButton.styles';
import {
  ButtonVariant,
} from '../Button/Button.types';
import { getIconColor } from '../Button/Button.helpers';
import {
  CustomIconButtonProps,
  IconButtonSizes,
} from './IconButton.types';

export type IconButtonProps = MergeElementProps<
  'button',
  CustomIconButtonProps
>

export const IconButton = forwardRef(({
  type = 'button',
  onClick,
  variant = ButtonVariant.PRIMARY,
  isDisabled,
  icon,
  size = IconButtonSizes.REGULAR,
  ...props
}: IconButtonProps, ref: Ref<HTMLButtonElement>) => {
  const [
    isHover,
    setIsHover,
  ] = useState(false);

  const iconSizes = {
    [IconButtonSizes.SMALL]: 24,
    [IconButtonSizes.REGULAR]: 32,
    [IconButtonSizes.HUGE]: 32,
  };

  const iconColor = useMemo(
    () => getIconColor({
      isDisabled,
      isHover,
      isIconButton: true,
      theme,
      variant,
    }),
    [
      variant,
      isHover,
      isDisabled,
    ]
  );

  return (
    <StyledIconButton
      ref={ref}
      type={type}
      onClick={onClick}
      variant={variant}
      disabled={isDisabled}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      size={size}
      {...props}
    >
      { React.cloneElement(icon, {
        fill: iconColor,
        size: iconSizes[size],
      }) }
    </StyledIconButton>
  );
});
