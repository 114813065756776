import styled from 'styled-components';

import { BarProps } from './Bar';

export const StyledBar = styled.div<BarProps>`
  display: flex;
  width: ${({ percentageWidth }) => percentageWidth}%;
  height: ${({ height }) => height}px;
  background: linear-gradient(90deg, rgba(186, 218, 255, 1) 0%, rgba(186, 218, 255, 1) 56%, rgba(189, 246, 232, 1) 56%, rgba(189, 246, 232, 1) 78%, rgba(250, 237, 190, 1) 78%, rgba(250, 237, 190, 1) 100%);
`;
