import styled from 'styled-components';

import { margins } from '@/core/components/Grid';

import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

export const MarginWrapper = styled.div`
  max-width: 1760px;
  margin: 0 ${sizes.phone.margin}px;

  @media ${breakpoint.sm} {
    ${margins(sizes.tablet.margin)};
  }

  @media ${breakpoint.md} {
    ${margins(sizes.desktop.margin)};
  }

  @media ${breakpoint.lg} {
    margin: 0 auto;
  }
`;
