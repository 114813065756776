import {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';

import { breakpoint } from '@/themes/breakpoints';

import {
  GlobalContextProviderProps,
  GlobalContextState,
} from './GlobalContext.props';

const GlobalContext = createContext<GlobalContextState | undefined>(undefined);

export const GlobalContextProvider = ({ children }: GlobalContextProviderProps) => {
  const desktop = useMediaQuery({ query: breakpoint.sm });

  useEffect(() => {
    setIsDesktop(desktop);
  }, [desktop]);

  const [
    isDesktop,
    setIsDesktop,
  ] = useState(true);

  return (
    <GlobalContext.Provider
      value={{
        isDesktop,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  if (context === undefined) {
    throw new Error('useGlobalContext must be used within a GlobalContext provider');
  }

  return context;
};
