import { IconProps } from './Icon.types';

export const Linkedin = ({
  size = 24,
  fill = '#000',
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.77755 4.88973C6.77729 5.39067 6.57806 5.87099 6.22367 6.22502C5.86928 6.57906 5.38876 6.77782 4.88783 6.77757C4.38689 6.77732 3.90658 6.57808 3.55254 6.22369C3.1985 5.8693 2.99975 5.38878 3 4.88784C3.00025 4.3869 3.19949 3.90658 3.55388 3.55254C3.90827 3.19851 4.38878 2.99975 4.88972 3C5.39065 3.00025 5.87097 3.19949 6.225 3.55388C6.57904 3.90827 6.7778 4.38879 6.77755 4.88973ZM6.83421 8.17621H3.05666V20H6.83421V8.17621ZM12.8027 8.17621H9.04407V20H12.765V13.7953C12.765 10.3389 17.2697 10.0178 17.2697 13.7953V20H21V12.511C21 6.68407 14.3326 6.90128 12.765 9.76279L12.8027 8.17621Z"
    />
  </svg>
);
