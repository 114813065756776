import Link from 'next/link';
import { useIntl } from 'react-intl';

import { routePaths } from '@/core/constants/routes';
import { navigationMessages } from '@/core/messages/navigation.messages';
import { FOOTER_NAVIGATION } from '@/core/constants/constants';

import {
  Links,
  Wrapper,
} from './Navigation.styles';

type messageIndex = keyof typeof navigationMessages;

export const Navigation = () => {
  const { formatMessage } = useIntl();

  return (
    <Wrapper>
      <Links>
        {FOOTER_NAVIGATION.map(link => {
          const path = routePaths[link as keyof typeof routePaths];

          return path && (
            <Link
              key={link}
              href={path}
            >
              {
                navigationMessages[link as messageIndex] ?
                  formatMessage(navigationMessages[link as messageIndex]) :
                  ''
              }
            </Link>
          );
        })}
      </Links>
    </Wrapper>
  );
};
