import Head from 'next/head';
import { ReactNode } from 'react';

import {
  ContentWrapper,
  PageWrapper,
} from '@/core/components/Layout/Layout.styles';

import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';

interface LayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => (
  <PageWrapper>
    <Head>
      <title key="title">Example Title</title>
    </Head>

    <Header />

    <ContentWrapper>
      {children}
    </ContentWrapper>

    <Footer />
  </PageWrapper>
);
