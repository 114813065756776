import { AppProps } from 'next/app';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
import omit from 'lodash/omit';

import { Layout } from '@/core/components/Layout';
import { Translation } from '@/core/components/Translation';
import { useTheme } from '@/core/hooks/useTheme';
import { GlobalContextProvider } from '@/core/contexts/GlobalContext/GlobalContext';

import { GlobalStyle } from '@/themes/globalStyles';

const MyApp = ({
  Component,
  pageProps: {
    messages,
    ...pageProps
  },
}: AppProps) => {
  const theme = useTheme();

  const cleanedPageProps = omit(pageProps, ['messages']);

  return (
    // @ts-expect-error React bad types
    <ThemeProvider theme={{ ...theme }}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1"
        />
      </Head>
      {
        // @ts-expect-error React bad types
        <GlobalStyle />
      }
      <Translation serverMessages={messages}>
        <GlobalContextProvider>
          <Layout>
            <Component {...cleanedPageProps} />
          </Layout>
        </GlobalContextProvider>
      </Translation>
    </ThemeProvider>
  );
};

export default MyApp;
