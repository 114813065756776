import {
  breakpoint,
  sizes,
} from '@/themes/breakpoints';

import { routePaths } from './routes';

export enum LoginStatesValues {
  LOADING = 'loading',
  AUTHENTICATED = 'authenticated',
  UNAUTHENTICATED = 'unauthenticated'
}

export type LoginStates = LoginStatesValues.AUTHENTICATED |
  LoginStatesValues.LOADING |
  LoginStatesValues.UNAUTHENTICATED;

export enum UnrestrictedEnpoints {
  CREATE = 'v1/auth/jwt/create/',
  REFRESH = 'v1/auth/jwt/refresh/',
}

export enum ThemeDirection {
  RTL = 'rtl',
  LTR = 'ltr'
}

/* eslint-disable sort-keys */
export const MediaQueries = {
  PHONE: `(max-width: ${sizes.tablet.minWidth - 1}px)` as const,
  TABLET: `${breakpoint.sm}` as const,
  DESKTOP: `${breakpoint.md}` as const,
  DESKTOP_LARGE: `${breakpoint.lg}` as const,
};

type NavigationPartialType = Array<Partial<keyof typeof routePaths>>;

export const FOOTER_NAVIGATION: NavigationPartialType = [
  'about',
  'articles',
  'book',
  'contact',
  'legal',
  'privacyPolicy',
  'pressPack',
];

export const HEADER_NAVIGATION: NavigationPartialType = [
  'about',
  'articles',
  'moments',
  'book',
  'media',
  'contact',
];
