import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const PageHeader = styled.header`
  position: sticky;
  top: 0;
`;

export const ContentWrapper = styled.main`
  flex: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 92px;
`;
