import Link from 'next/link';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';

import { HeaderWrapper } from '@/core/components/HeaderWrapper';
import { Bar } from '@/core/components/Bar/Bar';
import { routePaths } from '@/core/constants/routes';
import { Logo } from '@/core/components/Logo';
import { IconButton } from '@/core/components/IconButton';
import { Close } from '@/core/components/Icons/Close';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { IconButtonSizes } from '@/core/components/IconButton/IconButton.types';
import { useGlobalContext } from '@/core/contexts/GlobalContext/GlobalContext';

import {
  LogoWrapper,
  StickyHeader,
  Wrapper,
} from './Header.styles';
import { Navigation } from './Navigation/Navigation';
import { Hamburger } from '../../Icons';

export const Header = () => {
  const { isDesktop } = useGlobalContext();

  const [
    isMobileMenuOpen,
    setIsMobileMenuOpen,
  ] = useState(isDesktop);

  useEffect(() => {
    if (!isDesktop) {
      setIsMobileMenuOpen(false);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (!isDesktop && isMobileMenuOpen) {
      document.body.classList.add('menu-opened');
    } else {
      document.body.classList.remove('menu-opened');
    }
  }, [
    isMobileMenuOpen,
    isDesktop,
  ]);

  const toggleNavigation = () => setIsMobileMenuOpen(prevValue => !prevValue);

  const mobileButton = useMemo(
    () => (isMobileMenuOpen ? <Close /> : <Hamburger />),
    [isMobileMenuOpen]
  );

  return (
    <StickyHeader $isMobileMenuOpen={isMobileMenuOpen}>
      <Bar
        percentageWidth={100}
        height={8}
      />
      <HeaderWrapper>
        <Wrapper>
          <LogoWrapper>
            <Link href={routePaths.home}>
              <Logo />
            </Link>
            {!isDesktop && (
              <IconButton
                variant={ButtonVariant.SECONDARY}
                size={IconButtonSizes.SMALL}
                icon={mobileButton}
                onClick={toggleNavigation}
              />
            )}
          </LogoWrapper>
          {(isDesktop || isMobileMenuOpen) && (
            <Navigation />
          )}
        </Wrapper>
      </HeaderWrapper>
    </StickyHeader>
  );
};
