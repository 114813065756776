import { defineMessages } from 'react-intl';

export const globalMessages = defineMessages({
  'ar-SA': {
    defaultMessage: 'Arabic',
    description: 'Arabic language label',
    id: 'global.arabic',
  },
  en: {
    defaultMessage: 'English',
    description: 'English language label',
    id: 'global.english',
  },
});
