import Link from 'next/link';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { LinkButton } from '@/core/components/LinkButton/LinkButton';
import { Lang } from '@/core/components/Icons';
import { ButtonVariant } from '@/core/components/Button/Button.types';
import { globalMessages } from '@/core/messages/global.messages';
import { navigationMessages } from '@/core/messages/navigation.messages';
import { HEADER_NAVIGATION } from '@/core/constants/constants';

import {
  LanguageButton,
  Links,
  Wrapper,
} from './Navigation.styles';

export const Navigation = () => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const { locale } = useIntl();
  const {
    pathname,
    asPath,
    query,
  } = router;

  const changeLocale = () => {
    const newLocale = locale === 'en' ? 'ar-SA' : 'en';

    return router.push({
      pathname,
      query,
    }, asPath, { locale: newLocale });
  };

  return (
    <Wrapper>
      <Links>
        {HEADER_NAVIGATION.map(link => (
          <LinkButton
            key={link}
            renderAs={Link}
            label={formatMessage(navigationMessages[link as keyof typeof navigationMessages])}
            href={link}
          />
        ))}
      </Links>
      <LanguageButton
        variant={ButtonVariant.SECONDARY}
        startIcon={<Lang />}
        label={formatMessage(globalMessages[locale as keyof typeof globalMessages])}
        onClick={changeLocale}
      />
    </Wrapper>
  );
};
