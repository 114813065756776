import styled, { css } from 'styled-components';

import { ButtonProps } from './Button';
import { getButtonTheme } from './Button.helpers';
import { ButtonVariant } from './Button.types';

export const StyledButton = styled.button<ButtonProps>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 16px 24px;
  ${({
    variant,
    startIcon,
    endIcon,
  }) => variant === ButtonVariant.GHOST && (startIcon || endIcon) && css`
    padding: 0;
  `};
  ${({
    startIcon,
    endIcon,
  }) => !!startIcon && !endIcon && css`
    padding-inline-end: 32px;
  `}
  ${({
    startIcon,
    endIcon,
  }) => !startIcon && !!endIcon && css`
    padding-inline-start: 32px;
  `}
  gap: 12px;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.semiBold};
  font-size: ${({ theme: { typography } }) => typography.sizes.bodySmall};
  font-family: inherit;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;

  &:not(:disabled) {
    &:active {
      opacity: 0.88;
    }
    cursor: pointer;
  }
  ${({
    theme,
    variant,
  }) => getButtonTheme({
    theme,
    variant,
  })}
`;

export const IconWrapper = styled.div<{ isGhost: boolean }>`
  display: flex;
  ${({ isGhost }) => isGhost && css`
    border: 1px solid ${({ theme }) => theme.colors.alpha.black16};
    border-radius: 40px;
    padding: 15px;
  `}
`;
