import styled, { css } from 'styled-components';

import { IconButton } from '@/core/components/IconButton';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 0;

  @media ${breakpoint.sm} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const MenuIconButton = styled(IconButton)`
  @media ${breakpoint.sm} {
    display: none;
  }
`;

export const StickyHeader = styled.header<{ $isMobileMenuOpen: boolean }>`
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  background-color: ${({ theme: { colors } }) => colors.white};
  ${({ $isMobileMenuOpen }) => $isMobileMenuOpen && css`
    height: 100vh;

    @media ${breakpoint.sm} {
      height: auto;
    }
  `}
`;
