import styled, { css } from 'styled-components';

export const H1 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h1};
  line-height: 84px;
  letter-spacing: 0.3px;
`;

export const H2 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h2};
  line-height: 56px;
  letter-spacing: 0.3px;
`;

export const H3 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h3};
  line-height: 48px;
  letter-spacing: 0.3px;
`;

export const H4 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h4};
  line-height: 40px;
  letter-spacing: 0.3px;
`;

export const H5 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h5};
  line-height: 24px;
  letter-spacing: 0.3px;
`;

export const H6 = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.h6};
  line-height: 24px;
  letter-spacing: 0.3px;
`;

export const Subhead = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  font-size: ${({ theme: { typography } }) => typography.sizes.subhead};
  line-height: 28px;
  letter-spacing: 0.3px;
`;

export const BodyLarge = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.medium};
  font-size: ${({ theme: { typography } }) => typography.sizes.bodyLarge};
  line-height: 32px;
  letter-spacing: 0.3px;
`;

export const BodyMedium = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.bodyMedium};
  line-height: 32px;
  letter-spacing: 0.3px;
`;

export const BodySmall = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.bodySmall};
  line-height: 24px;
  letter-spacing: 0.3px;
`;

export const Caption = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.caption};
  line-height: 16px;
  letter-spacing: 0.3px;
`;

export const Button = css`
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.bold};
  font-size: ${({ theme: { typography } }) => typography.sizes.button};
  line-height: 24px;
  letter-spacing: 2px;
`;

export const Quote = css`
  font-style: italic;
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.regular};
  font-size: ${({ theme: { typography } }) => typography.sizes.quote};
  font-family: var(--executive-website-heading-font-family);
  line-height: 72px;
  letter-spacing: 0.3px;
`;
