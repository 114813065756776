import { StyledBar } from './Bar.styles';

export type BarProps = {
  percentageWidth: number;
  height: number;
}

export const Bar = ({
  percentageWidth,
  height,
  ...props
}: BarProps) => (
  <StyledBar
    percentageWidth={percentageWidth}
    height={height}
    {...props}
  />
);
