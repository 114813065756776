import { ReactElement } from 'react';

import { ButtonVariant } from '../Button/Button.types';

export enum IconButtonSizes {
  SMALL = 'small',
  REGULAR = 'regular',
  HUGE = 'huge',
}

export interface CustomIconButtonProps {
  variant: ButtonVariant;
  type?: 'button' | 'submit';
  icon: ReactElement;
  size?: IconButtonSizes;
  isDisabled?: boolean;
}
