import styled from 'styled-components';

import { breakpoint } from '@/themes/breakpoints';

export const Wrapper = styled.footer`
  background-color: ${({ theme: { colors } }) => colors.black};
  color: ${({ theme: { colors } }) => colors.neutral[100]};
`;

export const LogoWrapper = styled.div`
  padding: 40px 0 24px;
  text-align: center;

  @media ${breakpoint.sm} {
    padding: 80px 0 64px;
  }
`;
