import styled, { css } from 'styled-components';

import { IconButtonProps } from './IconButton';
import { getButtonTheme } from '../Button/Button.helpers';
import { getSizeRelatedProperties } from './IconButton.helpers';

export const StyledIconButton = styled.button<Omit<IconButtonProps, 'icon'>>`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none;

  &:not(:disabled) {
    &:active {
      opacity: 0.88;
    }
    cursor: pointer;
  }
  ${({ size }) => size && getSizeRelatedProperties(size)}
  ${({
    theme,
    variant,
  }) => getButtonTheme({
    isIconButton: true,
    theme,
    variant,
  })}
`;

export const IconWrapper = styled.div<{ isGhost: boolean }>`
  display: flex;
  ${({ isGhost }) => isGhost && css`
    border: 1px solid ${({ theme }) => theme.colors.alpha.black16};
    border-radius: 40px;
    padding: 15px;
  `}
`;
