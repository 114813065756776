/* eslint-disable sort-keys */
export type MediaBreakpoint = typeof breakpoint[keyof typeof breakpoint];

export const sizes = {
  phone: {
    columns: 4,
    gap: 8,
    margin: 16,
    minWidth: 375 as const,
  },
  tablet: {
    columns: 8,
    gap: 24,
    margin: 32,
    minWidth: 768 as const,
  },
  desktop: {
    columns: 12,
    gap: 24,
    margin: 80,
    minWidth: 1440 as const,
  },
  desktopLarge: {
    columns: 12,
    gap: 24,
    margin: 80,
    minWidth: 1920 as const,
  },
};

export const headerSizes = {
  ...sizes,
  desktop: {
    columns: 12,
    gap: 24,
    margin: 40,
    minWidth: 1440 as const,
  },
  desktopLarge: {
    columns: 12,
    gap: 24,
    margin: 40,
    minWidth: 1920 as const,
  },
};

export const breakpoint = {
  xs: `(min-width: ${sizes.phone.minWidth}px)` as const,
  sm: `(min-width: ${sizes.tablet.minWidth}px)` as const,
  md: `(min-width: ${sizes.desktop.minWidth}px)` as const,
  lg: `(min-width: ${sizes.desktopLarge.minWidth}px)` as const,
};

