export const routePaths = {
  about: '/about',
  articles: '/articles',
  book: '/book',
  contact: '/contact',
  error: '/404',
  home: '/',
  legal: '/legal',
  media: '/media',
  moments: '/moments',
  pressPack: '/press-pack',
  privacyPolicy: '/privacy-policy',
};
