import { css } from 'styled-components';

import {
  ButtonThemeProps,
  ButtonVariant,
} from './Button.types';
import { IconWrapper } from './Button.styles';

export const getButtonTheme = ({
  variant,
  theme,
  isIconButton = false,
}: ButtonThemeProps) => {
  switch (variant) {
    case ButtonVariant.PRIMARY:
      return css`
        background-color: ${theme.colors.primaryBlue[900]};
        color: ${theme.colors.neutral[100]};

        &:hover {
          background-color: ${theme.colors.primaryBlue[700]};
          color: ${theme.colors.white};
        }

        &:disabled {
          background-color: ${theme.colors.neutral[100]};
          color: ${theme.colors.neutral[500]};
        }
      `;
    case ButtonVariant.PRIMARY_INVERTED:
      return css`
        background-color: ${theme.colors.primaryBlue[900]};
        color: ${theme.colors.neutral[100]};

        &:hover {
          background-color: ${theme.colors.primaryBlue[700]};
          color: ${theme.colors.white};
        }

        &:disabled {
          background-color: ${theme.colors.primaryBlue[700]};
          color: ${theme.colors.white};
        }
      `;
    case ButtonVariant.SECONDARY:
      return css`
        border: 1px solid ${theme.colors.alpha.black16};
        background-color: ${theme.colors.white};
        color: ${theme.colors.black};

        &:hover {
          background-color: ${theme.colors.black};
          color: ${theme.colors.neutral[100]};
        }

        &:disabled {
          border: 1px solid ${theme.colors.alpha.black8};
          background-color: ${theme.colors.white};
          color: ${theme.colors.neutral[500]};
        }
      `;
    case ButtonVariant.GHOST:
      return css`
        background-color: transparent;
        color: ${theme.colors.black};

        ${IconWrapper} {
          background-color: transparent;
        }

        &:hover {
          color: ${theme.colors.black};

          ${IconWrapper} {
            background-color: ${theme.colors.black};
          }
        }

        &:disabled {
          color: ${theme.colors.neutral[500]};

          ${IconWrapper} {
            background-color: inherit;
          }
        }
      `;

    default:
      return css``;
  }
};

export const getIconColor = ({
  variant,
  theme,
  isHover,
  isDisabled,
  isIconButton,
}: ButtonThemeProps) => {
  if (isDisabled) {
    return theme.colors.neutral[500];
  }

  switch (variant) {
    case ButtonVariant.PRIMARY:
    case ButtonVariant.PRIMARY_INVERTED:
      return isHover ? theme.colors.white : theme.colors.neutral[100];
    case ButtonVariant.SECONDARY:
      return isHover ? theme.colors.neutral[100] : theme.colors.black;
    case ButtonVariant.GHOST:
      return isHover && !isIconButton ? theme.colors.white : theme.colors.black;
    default:
      return theme.colors.black;
  }
};
