/* eslint-disable sort-keys */
const colors = {
  black: '#1C1D1E',
  white: '#FFF',
  decorativeBlue: {
    100: '#E0EFFF',
    200: '#D1E7FF',
    300: '#BADAFF',
    400: '#99C9FF',
    500: '#7AB8FF',
  },
  primaryBlue: {
    100: '#E9F6FF',
    200: '#D9EFFF',
    300: '#BEE3FF',
    400: '#A5D8FF',
    500: '#88C6FF',
    700: '#478EFB',
    900: '#2472EA',
  },
  green: {
    100: '#CEF5EB',
    200: '#BDF6E8',
    400: '#85E8CF',
  },
  yellow: {
    100: '#FAEDBE',
    300: '#E9D58B',
    400: '#FFD633',
  },
  neutral: {
    100: '#FAFAF9',
    200: '#F5F5F4',
    300: '#ECECEC',
    400: '#D6D3D1',
    500: '#A8A29E',
    600: '#78716C',
    700: '#57534E',
  },
  semantic: {
    caution: {
      100: '#FFF7CC',
      500: '#FFBF02',
    },
    danger: {
      100: '#FFEEE8',
      500: '#B74763',
    },
    info: {
      100: '#D5F4FF',
      500: '#207ADB',
    },
    success: {
      100: '#F1FDDE',
      500: '#63B93D',
    },
  },
  alpha: {
    black16: '#0D0D0D28',
    black24: '#0D0D0D3D',
    black8: '#0D0D0D14',
    white16: '#FFFFFF28',
    white32: '#FFFFFF51',
    white8: '#FFFFFF14',
  },
};

const mainColors = {
  green: colors.green[100],
  blue: colors.decorativeBlue[200],
  yellow: colors.yellow[100],
};

const typography = {
  colors: {
    black: colors.black,
    blue: colors.primaryBlue[900],
    white: colors.white,
    gray: colors.neutral[700],
  },
  fontFamily: {
    headline: 'Amiri',
    regular: 'NotoSans',
  },
  sizes: {
    h1: '80px',
    h2: '48px',
    h3: '40px',
    h4: '32px',
    h5: '24px',
    h6: '18px',
    subhead: '16px',
    bodyLarge: '18px',
    bodyMedium: '16px',
    bodySmall: '14px',
    caption: '12px',
    button: '14px',
    quote: '64px',
  },
};

const spacers = {
  space50: '4px',
  space100: '8px',
  space200: '12px',
  space300: '16px',
  space400: '20px',
  space500: '24px',
  space600: '32px',
  space700: '40px',
  space800: '48px',
  space900: '56px',
};

const fontWeight = {
  thin: 300,
  regular: 400,
  semiMedium: 500,
  medium: 600,
  semiBold: 700,
  bold: 800,
};
/* eslint-enable sort-keys */

export const theme = {
  backgroundColor: colors.white,
  colors: {
    ...colors,
    mainColors,
  },
  fontWeight,
  spacers,
  typography,
};
